<template>
  <v-container>
    <v-row>
      <v-col cols="8" offset-lg="2" offset-md="2">
        <Breadcrumbs
          :items="[
            {
              text: 'All Users',
              disabled: false,
              href: '/app/users'
            },
            {
              text: !!$route.query.id ? 'Edit User' : 'Add User',
              disabled: true,
              href: !!$route.query.id ? '/app/users/add' : '/app/users'
            }
          ]"
        />
        <div class="d-flex flex-column mb-3 ml-1 mt-n4">
          <PageTitle
            text="Users"
            class=" ft font-weight-bold font-size-lg mb-4"
          />
        </div>
        <div v-if="isListLoading" class="d-flex flex-column mx-2 flex-grow-1">
          <v-progress-circular color="primary" indeterminate />
        </div>
        <div v-else class="d-flex flex-column mx-2 flex-grow-1">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(addNewUser)">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  label="Name of User"
                  outlined
                  v-model="user.name"
                  hint="Enter the name of the user"
                  class="ft font-weight-medium font-size-md"
                  :error-messages="errors[0]"
                />
              </ValidationProvider>
              <div class="d-flex flex-row flex-grow-1">
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Email Address"
                    outlined
                    :disabled="$route.query.id && $route.query.action !== 'add'"
                    v-model="user.email"
                    :hint="`Enter the email address of the user`"
                    :error-messages="errors[0]"
                    class="ft font-weight-medium font-size-md mr-1"
                  />
                </ValidationProvider>
                <ValidationProvider tag="div" class="d-flex flex-grow-1">
                  <v-text-field
                    label="Username"
                    v-model="user.username"
                    outlined
                    :hint="`Enter your username here`"
                    class="ft font-weight-medium font-size-md ml-1"
                  />
                </ValidationProvider>
              </div>
              <div class="d-flex flex-row flex-grow-1">
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Mobile Phone Number"
                    outlined
                    v-model="user.mobile"
                    :hint="`Enter the phone number of the user`"
                    :error-messages="errors[0]"
                    class="ft font-weight-medium font-size-md mr-1"
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-if="!$route.query.id && $route.query.action === 'add'"
                  rules="required"
                  tag="div"
                  class="d-flex flex-grow-1"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Password"
                    v-model="user.password"
                    outlined
                    :error-messages="errors[0]"
                    :type="show ? 'text' : 'password'"
                    :append-icon="!show ? 'visibility' : 'visibility_off'"
                    @click:append="show = !show"
                    :hint="`Enter users' password here`"
                    class="ft font-weight-medium font-size-md ml-1"
                  />
                </ValidationProvider>
              </div>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-autocomplete
                  :items="roles"
                  :error-messages="errors[0]"
                  item-value="id"
                  v-model="user.role"
                  item-text="name"
                  outlined
                  label="Assign Role To A User"
                  color="primary"
                  class="ft font-weight-medium font-size-sm"
                >
                  <template #item="{item}">
                    <span class="ft font-weight-medium font-size-sm">
                      {{ item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
              <div class="d-flex flex-row flex-grow-1 mt-n6">
                <v-checkbox v-model="user.isActive" class="pa-0">
                  <template #label>
                    <span class=" ft font-weight-medium font-size-md ">
                      Activate or deactivate the user by checking/unchecking the
                      box respectively</span
                    >
                  </template>
                </v-checkbox>
              </div>

              <div
                class="d-flex flex-column flex-grow-1 mt-n2 box-shadow-light mt-3 px-2"
                style="border-radius: 8px"
              >
                <div class="d-flex flex-row flex-grow-1 pt-3 pb-1">
                  <i class="material-icons-outlined absolute">notifications</i
                  ><span
                    class="ft font-weight-medium font-size-md text-uppercase pl-2"
                    >SMS/Push Notification Preferences</span
                  >
                </div>
                <i class="ft font-weight-medium font-size-sm mb-2"
                  >Set which notifications (both sms and browser push
                  notifications) a user should receive
                </i>
              </div>
              <div class="d-flex flex-row flex-grow-1 flex-wrap mt-n1">
                <div class="d-flex flex-column flex-grow-1 mt-n1">
                  <v-checkbox
                    v-model="preferences.fuelSalesAlert"
                    dense
                    hide-details
                    class="ft  text-sm"
                  >
                    <template #label>
                      <span class="ft black--text  font-size-md text-capitalize"
                        >Fuel Sales</span
                      >
                    </template>
                  </v-checkbox>
                  <div
                    class="ft font-weight-medium"
                    style="font-size: 14px;font-style:italic;"
                  >
                    <q
                      >Receive push notifications, sms alert on recording of
                      daily fuel sales</q
                    >
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 mt-n1">
                  <v-checkbox
                    dense
                    v-model="preferences.lubeSalesAlert"
                    hide-details
                    class="ft  text-sm"
                  >
                    <template #label>
                      <span class="ft black--text font-size-md text-capitalize"
                        >Lube Sales</span
                      >
                    </template>
                  </v-checkbox>
                  <div
                    class="ft font-weight-medium"
                    style="font-size: 14px;font-style:italic;"
                  >
                    <q
                      >Receive push notifications, sms alert on recording of
                      daily lube sales</q
                    >
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 mt-n1">
                  <v-checkbox
                    v-model="preferences.shopSalesAlert"
                    dense
                    hide-details
                    class="ft  text-sm"
                  >
                    <template #label>
                      <span class="ft black--text font-size-md text-capitalize"
                        >Shop Sales</span
                      >
                    </template>
                  </v-checkbox>
                  <div
                    class="ft font-weight-medium"
                    style="font-size: 14px;font-style:italic;"
                  >
                    <q
                      >Receive push notifications, sms alert on recording of
                      daily shop sales</q
                    >
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 mt-n1">
                  <v-checkbox
                    v-model="preferences.stocksAlert"
                    dense
                    hide-details
                    class="ft  text-sm"
                  >
                    <template #label>
                      <span class="ft black--text font-size-md text-capitalize"
                        >Stocks Level</span
                      >
                    </template>
                  </v-checkbox>
                  <div
                    class="ft font-weight-medium"
                    style="font-size: 14px;font-style:italic;"
                  >
                    <q
                      >Receive push notifications, sms alert on daily stock
                      levels for both products/fuels and lubes</q
                    >
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 mt-n1">
                  <v-checkbox
                    v-model="preferences.variationsAlert"
                    dense
                    hide-details
                    class="ft  text-sm"
                  >
                    <template #label>
                      <span class="ft black--text font-size-md text-capitalize"
                        >Fuels Variations
                      </span>
                    </template>
                  </v-checkbox>
                  <div
                    class="ft font-weight-medium"
                    style="font-size: 14px;font-style:italic;"
                  >
                    <q
                      >Receive push notifications, sms alert including fuel
                      variations and fuels sold</q
                    >
                  </div>
                </div>
              </div>
              <v-spacer />
              <div
                class="d-flex flex-row flex-grow-1 mt-3"
                style="justify-content: flex-end;"
              >
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="loading"
                  :loading="loading"
                >
                  <span class="ft font-weight-medium font-size-sm"
                    >Add User</span
                  >
                </v-btn>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";
import SnackBarMixins from "../../../../mixins/SnackBarMixins";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("email", {
  ...email,
  message: `Input must be a valid email address`
});
export default {
  name: "AddUser",
  components: {
    ValidationObserver,
    ValidationProvider,
    SnackBar: () => import(`../../../../components/SnackBar`),
    Breadcrumbs: () => import(`../../../../components/Breadcrumbs`),
    PageTitle: () => import(`../../../../components/PageTitle`)
  },
  computed: {
    ...mapGetters({
      roles: "acl/getRoleList",
      loading: "getIsLoading",
      details: "users/getUserDetails",
      isListLoading: "getIsListLoading"
    })
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      show: false,
      user: {
        name: "",
        email: "",
        role: "",
        mobile: "",
        username: "",
        password: "",
        isActive: true,
        userType: "operations"
      },
      preferences: {
        fuelSalesAlert: false,
        lubeSalesAlert: false,
        shopSalesAlert: false,
        stocksAlert: false,
        variationsAlert: false
      }
    };
  },
  watch: {
    details(payload) {
      const { userSMSNotificationPreference, ...rest } = payload;
      this.user = { ...rest };
      this.preferences = { ...userSMSNotificationPreference };
    }
  },
  methods: {
    addNewUser() {
      if (!!this.$route.query.id && this.$route.query.action !== "add") {
        const { id, email, mobile, isActive, username, name, role } = this.user;
        this.$store.dispatch("users/update", {
          id,
          email,
          mobile,
          isActive,
          username,
          name,
          role,
          preferences: this.preferences
        });
      } else {
        this.$store.dispatch("users/create", {
          ...this.user,
          preferences: this.preferences
        });
      }
    }
  },
  created() {
    if (!!this.$route.query.id && this.$route.query.action !== "add") {
      this.$store.dispatch("users/details", { id: this.$route.query.id });
    }
    this.$store.dispatch("acl/listOfRoles");
  }
};
</script>
